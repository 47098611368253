const routes = [
  {
    path: ["/"],
    exact: true,
    component: "Home",
  },
  {
    path: ["/proposta/detalhe"],
    exact: true,
    component: "PageDetalhesProposta",
  },
  {
    path: ["/proposta/andamento"],
    exact: true,
    component: "PageAndamentoProposta",
  },
  {
    path: ["/proposta/edicao"],
    exact: true,
    component: "PageEdicaoProposta",
  },
  {
    path: ["/proposta/pre-aprovada/edicao"],
    exact: true,
    component: "PageEdicaoPropostaAprovada",
  },
  {
    path: ["/proposta/pre-aprovada/revisao"],
    exact: true,
    component: "PageRevisaoPropostaAprovada",
  },
  {
    path: ["/proposta/aluguel-direto"],
    exact: true,
    component: "PageAluguelDireto",
  },
  {
    path: ["/proposta/cadastro/imobiliaria"],
    exact: true,
    component: "PageCadastroImobiliaria",
  },
];

export default routes;
