export const defaultTheme = {
    name: 'default',
    colors: {
        branco: "#FFF",
        primary: '#2D9A47',
        secondary: '#FF8727',
        cinzaMedio: "#8E8E8E",
        cinzaClaro: "#6F6F6F",
        verdeClaroMedio: "#B6C9C4",
        verdeEscuro: "#202C27",
        preto: "#000000",
        background: "#ECF0EF",
        border: "#ECF0EF",
    },
    buttons: {
        default: {
            color: '#2D9A47',
            backgroundColor: "transparent"
        },
        primary: {
            color: 'white',
            backgroundColor: "#2D9A47"
        },
        secondary: {
            color: 'white',
            backgroundColor: "#FF8727"
        },
        danger: {
            color: 'white',
            backgroundColor: "#202C27"
        }
    },
    sizes: {
        8: '0.5rem',
        10: '0.625rem',
        11: '0.688rem',
        12: '0.75rem',
        13: '0.813rem',
        14: '0.875rem',
        15: '0.938rem',
        18: '1.125rem',
        20: '1.25rem',
        24: '1.5rem',
        30: '1.875rem',
    },
    fontFamily: {
        300: 'AlbertSans Light',
        400: 'AlbertSans Regular',
        500: 'AlbertSans Medium',
        600: 'AlbertSans SemiBold',
        700: 'AlbertSans Bold',
    },
    maxWidht: {
        261: '261px',
        423: '423px',
        618: '618px',
    }
}