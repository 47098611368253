import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import React, { lazy } from "react";
import { Redirect, Route } from "react-router-dom";
import { JWTType } from "../../common/types";

const Header = lazy(() => import("../../components/Header"));
const Chat = lazy(() => import("../Chat"));

const RouteProtector: React.FC<{
  key?: string;
  component: any;
  path: string[];
  exact: boolean;
}> = (props) => {
  console.log("Autenticacao Home");

  if (Cookies.get("tokenAuth")) {
    console.log("Buscando redirect auth");
    const tokenAuth = Cookies.get("tokenAuth");
    if (tokenAuth) {
      const jwtToken = JSON.stringify(tokenAuth);

      let jwtDecoded: JWTType = jwt_decode(jwtToken || "") || null;

      sessionStorage.setItem("tokenAuth", jwtToken);
      sessionStorage.setItem("userEmail", jwtDecoded.user_name);

      Cookies.remove("tokenAuth", { path: "", domain: ".cashgo.com.br" });
    }
  }

  if (sessionStorage.getItem("tokenAuth") === null) {
    return <Redirect to={"/login"} />;
  }

  return (
    <>
      <Chat />
      <Header />
      <Route
        path={props.path}
        exact={props.exact}
        component={props.component}
      />
      {/* <Footer /> */}
    </>
  );
};
export default RouteProtector;
