import { createGlobalStyle } from "styled-components";

export const Styles = createGlobalStyle`

    @import '~antd/dist/antd.css';

    @font-face {
        font-family: "AlbertSans Light";
        src: url("/fonts/AlbertSans-Light.ttf") format("truetype");
        font-style: normal;
    }
    @font-face {
        font-family: "AlbertSans Medium";
        src: url("/fonts/AlbertSans-Medium.ttf") format("truetype");
        font-style: normal;
    }
    @font-face {
        font-family: "AlbertSans Regular";
        src: url("/fonts/AlbertSans-Regular.ttf") format("truetype");
        font-style: normal;
    }

    @font-face {
        font-family: "AlbertSans SemiBold";
        src: url("/fonts/AlbertSans-SemiBold.ttf") format("truetype");
        font-style: normal;
    }

    @font-face {
        font-family: "AlbertSans Bold";
        src: url("/fonts/AlbertSans-Bold.ttf") format("truetype");
        font-style: normal;
    }

    html,
    a {
        font-family: 'AlbertSans Regular', sans-serif;
    }

    body {
        margin:0;
        padding:0;
        border: 0;
        outline: 0;
        overflow-x: hidden;
        font-family: 'AlbertSans Regular', sans-serif;
    }

    h2 {
        line-height: 1.813rem;
        @media only screen and (min-width: 768px) {
            line-height: 2.375rem;
        }
    }

    h6 {
        line-height: 1.25rem;
        @media only screen and (min-width: 768px) {
            line-height: 1.375rem;
        }
    }

    a:hover {
        color: #18216d;
    }

    input {
        height: 45px !important;
        max-height: 45px;
        width: 100%;
    }
   

    a {
        text-decoration: none;
        outline: none;
        color: #2E186A;

        :hover {
            color: #2e186a;
        }
    }
    
    *:focus {
        outline: none;
    }

    .about-block-image svg {
        text-align: center;
    }

    .ant-drawer-body {
        display: flex;
        flex-direction: column;
        text-align: left;
        padding: 14px 12px;

    }

    .ant-drawer-content-wrapper {
        width: 220px !important;
    }

    iframe {
        border: 0;
        height: 85vh;
    }

    .modal-consulta-imobiliarias {
        top: 5px;
        .ant-modal-body {
            max-height: 80vh;
            min-height: 80vh;

        }

        .ant-radio-group {
            padding: 1rem 0;
            max-height: 70vh;
            overflow-y: auto;
            width: 100%;
        }
    }

    .ant-drawer-content {
        background-color: #202C27;
    }

    .ant-space-vertical{
        width: 100%;
    }

    .ant-input-affix-wrapper {
        padding-top: 0px;
        padding-bottom: 0px;
    }

    .ant-collapse {
        background: transparent;
    }

    .ant-message {
        
        .ant-message-notice-content {
            background-color: #202C27;
            color: #fff;
        }

        .ant-message-notice-close-x {
            color: #fff;
        }
    }

    .wpp-btn-icon {
        height: 1px;

        svg {
            height: 22px;
        }
    }
`;
