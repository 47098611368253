import { lazy, Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import RouteProtector from "../components/RouteProtector";
import WhatsappFloatButton from "../components/WhatsappFloatButton";
import Logout from "../pages/Logout";
import { Styles } from "../styles/styles";
import routes from "./config";

const Router = () => {
  return (
    <Suspense fallback={null}>
      <Styles />
      <WhatsappFloatButton />
      <Switch>
        <Route
          key={"Login"}
          path={["/login"]}
          exact={true}
          component={lazy(() => import("../pages/Login"))}
        />
        <Route
          key={"Logout"}
          path={["/logout"]}
          exact={true}
          component={Logout}
        />
        <Route
          key={"RecuperarSenha"}
          path={["/recuperar-senha"]}
          exact={true}
          component={lazy(() => import("../pages/RecuperarSenha"))}
        />
        <Route
          key={"AlterarSenha"}
          path={["/alterar-senha"]}
          exact={true}
          component={lazy(() => import("../pages/AlterarSenha"))}
        />
        {routes.map((routeItem) => {
          return (
            <RouteProtector
              key={routeItem.component}
              path={routeItem.path}
              exact={routeItem.exact}
              component={lazy(() => import(`../pages/${routeItem.component}`))}
            />
          );
        })}
      </Switch>
    </Suspense>
  );
};

export default Router;
